import { Component, InjectionToken, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import {
  DropdownData,
  FlatNode,
  HeaderConfig,
  LeftMenuComponent,
  LeftMenuConfig,
  MenusConfig,
  TopbarComponent,
  TopbarControlService,
  HeaderConfigService,
} from '@intersystems/header';
import { NotificationService } from '@intersystems/notification';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from './core/auth.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { User } from 'api';
import { TableConfigService } from '@intersystems/table';
import { ConfirmationDialogConfigService } from '@intersystems/confirmation-dialog';
import { MatDialog } from '@angular/material/dialog';
import { AppFeedbackComponent } from './core/dialogs/feedback.component';
import { SharedService } from './shared/services/shared.service';
import { MainMenuService } from './core/main-menu.service';
import { IscFormConfigService } from '@intersystems/isc-form';
import { IRISAuthService } from './deployments/icca-common/components/iris-login/iris-auth.service';
import { Location } from '@angular/common';
import { DeploymentsService } from './deployments/deployments.service';
import { TimeoutConfigService } from '@intersystems/timeout';
import * as dayjs from 'dayjs';

const SwaggerUI = () => import('swagger-ui').then(p => p.default);

export type LazySwaggerUI = ReturnType<typeof SwaggerUI>;
export const SWAGGER = new InjectionToken<LazySwaggerUI>('Lazily loaded Swagger UI', {
  providedIn: 'root',
  factory: SwaggerUI,
});

const CanvasJS = () => import('src/assets/js/canvasjs.min').then(p => p.default);

export type LazyCanvasJS = ReturnType<typeof CanvasJS>;
export const CANVASJS = new InjectionToken<LazyCanvasJS>('Lazily loaded CanvasJS', {
  providedIn: 'root',
  factory: CanvasJS,
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private deploymentsService: DeploymentsService,
    private router: Router,
    private topbarControlService: TopbarControlService,
    private headerConfigService: HeaderConfigService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private tableConfigService: TableConfigService,
    private formConfigService: IscFormConfigService,
    private confirmationDialogConfigService: ConfirmationDialogConfigService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private mainMenuService: MainMenuService,
    private timeoutConfigService: TimeoutConfigService,
    private _location: Location,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data),
      )
      .subscribe((event: { [name: string]: any }) => {
        if (event['openUrl']) {
          window.open(event['openUrl']);
          this._location.back();
        }
      });
  }

  @ViewChild('topbar', { static: true } as any) topbar: TopbarComponent;
  @ViewChild('leftMenu') leftMenu: LeftMenuComponent;

  user$: Observable<User>;
  showMenu$: Observable<boolean>;
  userMenuData$ = new BehaviorSubject<DropdownData[]>([]);
  activeAppId$ = new BehaviorSubject<string>('');
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  currentTenantId$: Observable<string>;
  userTenants: any;
  userTenantsOptions = [];

  treeConfig: LeftMenuConfig = {
    activeNodeId: this.activeAppId$,
    data: this.mainMenuService.menuData$,
    onItemClick: node => this.onMenuSelect(node),
    expanded: true,
  };

  appNameConfig = {
    appName: 'InterSystems Cloud Services Portal',
    useOnMobile: false,
  };

  usernameMenu: DropdownData[] = [
    {
      id: 'user-account',
      name: 'Account',
    },
    {
      id: 'user-documentation',
      name: 'Documentation',
    },
    {
      id: 'user-feedback',
      name: 'Submit Feedback',
    },
    {
      id: 'user-logout',
      name: 'Logout',
    },
  ];

  headerConfig: HeaderConfig = {
    companyLogo: {
      link: '/',
      src: '/assets/images/InterSystemsIcon.svg',
      altText: 'Home',
    },
    showSpacerOnMobile: true,
    useDivider: true,
    flexLayoutAlign: 'start center',
    flexLayoutAlignMobile: 'start center',
    useBackButton: false,
  };

  menusConfig: MenusConfig = {
    hamburgerMenu: {
      useOnDesktop: false,
      useOnMobile: true,
      data: this.mainMenuService.menuData$,
      activeNodeId: this.activeAppId$,
    },
    avatarMenu: {
      useOnMobile: true,
    },
    dropdown: {
      data: this.userMenuData$,
    },
    productLogo: {
      src: '/assets/images/Logo.svg',
      altText: 'InterSystems Cloud Portal',
    },
    onItemClick: node => this.onMenuSelect(node),
  };

  ngOnInit() {
    // We have to push user info into Top Bar again because it
    // does not get the initial value from its service correctly
    this.user$ = this.authService.user$.pipe(
      tap(user => {
        if (user) {
          this.userTenants = user.tenants;
          // Fill userTenantsOptions array for tenants-switcher
          if (this.userTenants) {
            this.userTenantsOptions = [];
            for (const [tenantId, value] of Object.entries(this.userTenants)) {
              this.userTenantsOptions.push({ name: value['name'], value: tenantId });
            }
          }
          this.currentTenantId$ = this.authService.currentTenantId$;
        }
      }),
      tap(user => this.updateTopBar(user)),
      takeUntil(this._unsubscribeAll),
    );
    this.user$.subscribe();

    this.showMenu$ = combineLatest([
      this.user$,
      this.deploymentsService.deployments$,
      this.router.events.pipe(filter(e => e instanceof NavigationEnd)) as Observable<NavigationEnd>,
    ]).pipe(map(([user, deployments, event]) => user && deployments && event.url.indexOf('/account/') == -1));

    const navigationEvents$ = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => this.activateMenu(e.urlAfterRedirects)),
      takeUntil(this._unsubscribeAll),
    );
    navigationEvents$.subscribe();

    // Initialise translation service
    this.headerConfigService.configureTranslation();
    this.tableConfigService.configureTranslation();
    this.formConfigService.configureTranslation();
    this.timeoutConfigService.configureTranslation();
    dayjs.locale('en');
    this.formConfigService.configureFormDateTime({ defaultDateFormat: 'YYYY-MM-DD', defaultTimeFormat: 'HH:mm' });
    this.confirmationDialogConfigService.configureTranslation();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  updateTopBar(user) {
    if (user) {
      this.topbarControlService.setUsernameData({
        userName: user['first_name'] + ' ' + user['last_name'],
        textAboveUserName: 'User:',
        hideOnMobile: false,
      });
      this.userMenuData$.next(this.usernameMenu);
    } else {
      this.topbarControlService.setUsernameData({});
      this.userMenuData$.next([]);
    }
  }

  onLogout() {
    this.topbar.showDropdown = false;
    this.authService.logOut(true).subscribe(() => {
      this.notificationService.showSuccess('Successfully Logged Out', 7000);
    });
  }

  onFeedback(): void {
    const dialogRef = this.dialog.open(AppFeedbackComponent);
    dialogRef
      .afterClosed()
      .pipe(
        filter(result => result),
        switchMap(feedback =>
          this.authService.submitFeedback(this.activatedRoute.snapshot.paramMap.get('deploymentId'), feedback),
        ),
        tap(() => this.sharedService.showSuccess('Thank you for your feedback. Our team will be notified.')),
        catchError(() =>
          of(
            this.sharedService.showAlert(
              'Error submitting feedback. Please send an email to portal-dev@isccloud.io detailing your feedback.',
            ),
          ),
        ),
      )
      .subscribe();
  }

  onSwitchTenantId(tenantId: string): void {
    this.authService.setCurrentTenantId(tenantId);
    this.userTenantsOptions = [];
    if (this.router.url == '/deployments') {
      this.sharedService.reloadCurrentRoute();
    } else {
      this.router.navigate(['/deployments']);
    }
    this.user$.subscribe();
  }

  onMenuSelect(item: FlatNode | DropdownData) {
    const itemArray = item.id.split('-');
    if (itemArray[0] == 'user') return this.onUserMenuSelect(item); //User menu
    if (itemArray[1] == 'deployments' && itemArray.length == 3) {
      const newUrl = this.router.url.split('/');
      newUrl.pop();
      newUrl.push(itemArray[2]);
      this.router.navigate(newUrl);
    } else if (itemArray.length == 3) {
      this.router.navigate([itemArray[1], itemArray[2]]);
    } else {
      this.router.navigate([itemArray[1]]);
    }
  }

  onUserMenuSelect(item: DropdownData) {
    if (item.id == 'user-logout') return this.onLogout();
    if (item.id == 'user-feedback') return this.onFeedback();
    if (item.id == 'user-account') return this.router.navigate(['/settings/account']);
    if (item.id == 'user-documentation')
      return window.open('https://docs.intersystems.com/services/csp/docbook/DocBook.UI.Page.cls', '_blank');
    return false;
  }

  activateMenu(url) {
    const urlArray = url.split('/');
    let activeId = '';
    if (urlArray.length == 4) activeId = 'nav-' + urlArray[1] + '-' + urlArray[3];
    else if (urlArray.length == 3) activeId = 'nav-' + urlArray[1] + '-' + urlArray[2];
    else activeId = 'nav-' + urlArray[1];
    this.activeAppId$.next(activeId);
  }
}
