import { Subscription } from '../core/interfaces/subscription';

export const SUBSCRIPTIONS_CODES = {
  fhiraas: '83lxl58o1miosrihkx2p0v0wu',
  hmts: 'asfw2iij7m0p33qtg5ks0ycrx',
  hcc: '7wtzm72jjcf4sojegrna77lxn',
  sqlaas: '6s6sk2rnzd9duyamgo7nji4d1',
  doc: '6uus626v99ktb1oikig6mtawv',
  b360: 'business360',
  hspd: 'hspd123456789',
  ics: 'exampleziouwbekufyaghsdf',
  lol: 'lol',
  spc: 'spc',
  scs: 'scs',
};

export const SUBSCRIPTIONS_DATA: Subscription[] = [
  {
    name: 'FHIR Server',
    description: `A fully managed, enterprise-grade FHIR® service,
        hosted on AWS and available in the US and Europe. InterSystems
        will securely manage protected health data (PHI) on
        AWS, making it easy to develop innovative healthcare
        applications using FHIR.`,
    type: 'fhiraas',
    productCode: SUBSCRIPTIONS_CODES.fhiraas,
    photoPath: 'assets/images/logos/fhiraas.png',
    awsUrl: 'https://aws.amazon.com/marketplace/pp/prodview-ftb7xzb6t7c3w?ref_=unifiedsearch',
    productOverview: `
      The world depends on powerful, smart applications. Healthcare interoperability is fundamental to improving patient care, decreasing healthcare costs, and giving providers a more accurate picture of a patient's health.
      <br><br>
      To remove barriers that hinder interoperability, InterSystems is launching a fully managed, enterprise-grade FHIR (Fast Health Interoperability Resources) service, initially hosted on the AWS cloud, and available in the US. InterSystems will securely manage protected health data (PHI) on AWS, making it easy to develop innovative healthcare applications using FHIR.
      <br><br>
      FHIR resources provide a simple, open, and flexible way to access data at a technical level, and the InterSystems FHIR Server provides an easy way to quickly provision a managed environment for your application. Whether you are a digital health developer building SMART on FHIR applications, a MedTech product manager wishing to add clinical data integration to devices, or a developer creating a mobile app targeting patient access to health records, it allows you to simply focus on building the best solution possible.`,
    soldBy: {
      companyName: 'InterSystems Corporation',
      url: 'https://aws.amazon.com/marketplace/seller-profile?id=6e5272fb-ecd1-4111-8691-e5e24229826f',
    },
    productVideoId: 'y81Es5GCFsc',
    fulfillmentMethod: 'Software as a Service (SaaS)',
    termsAndConditions: [
      { name: 'Cloud Subscription Agreement', url: 'https://www.intersystems.com/ICTerms' },
      { name: 'Secure Development Lifecycle', url: 'https://www.intersystems.com/SecureSDLC' },
      { name: 'Privacy Policy', url: 'https://www.intersystems.com/privacy-policy/' },
    ],
    pricing: {
      description: `This software is priced along a consumption dimension.
        Your bill will be determined by the number of units you use. Additional taxes or fees may apply.`,
      measure: 'Units',
      units: [
        {
          name: 'vCPU deployed per hour',
          aws: '$0.208 / unit',
          azure: 'Coming soon',
        },
        {
          name: 'Allocated storage per hour in 10 GB chunks',
          aws: '$0.005 / unit',
          azure: 'Coming soon',
        },
      ],
      subscriptionUrls: {
        aws: 'https://aws.amazon.com/marketplace/pp/prodview-ftb7xzb6t7c3w?ref_=unifiedsearch',
      },
    },
  },
  {
    name: 'Health Connect Cloud',
    description: `An integration engine that delivers high-volume
        transaction support, process management, and
        monitoring to support mission critical applications.`,
    type: 'hcc',
    productOverview: `<p>
      InterSystems HealthShare Health Connect Cloud is a healthcare integration engine that delivers
      high-volume transaction support, process management, and monitoring to support mission critical applications.
      <p>
    Health Connect delivers tangible benefits to leading care organizations, including:
    <ul>
        <li>Proven rapid scalability and fast implementation beyond competitor benchmarks
        <li>Zero-downtime stability and reliability for even the largest IDNs with thousands of connections and millions of daily message transactions
        <li>Faster interface development to save costs, with additional savings from eliminating separate vendor fees.
    </ul>
    <p>
    At the heart of Health Connect is a high performance, multi-model data engine that seamlessly handles multiple forms of data at high speed. Health Connect easily scales from serving small clinics to handling the transaction volumes of the largest and most complex healthcare delivery systems in the world. Capabilities include:
    <ul>
        <li>Interoperability by design
        <li>Mirroring with fast failover recovery
        <li>Source control for HL7 schemas
        <li>Intuitive drag-and-drop HL7 schema editing
        <li>A flexible, adaptable security model and more
    </ul>
    `,
    productCode: SUBSCRIPTIONS_CODES.hcc,
    productVideoId: 'u39uvnMuKaI',
    photoPath: 'assets/images/logos/hcc.png',
    pricing: {
      description: `This software is priced along a consumption dimension.
        Your bill will be determined by the number of units you use. Additional taxes or fees may apply.`,
      measure: 'Units',
      units: [
        {
          name: 'First 0-5,000,000 messages / month',
          aws: '$6600 / month',
        },
        {
          name: 'Messages / month (5,000,001 - 60 million)',
          aws: '$131.97 / 100,000',
        },

        {
          name: 'Messages / month (60,000,001 - 300 million)',
          aws: '$72.94 / 100,000',
        },

        {
          name: 'Messages / month (300,000,001 - 600 million)',
          aws: '$56.41 / 100,000',
        },

        {
          name: 'Messages / month (600,000,001 - 1.2 billion)',
          aws: '$41.10 / 100,000',
        },

        {
          name: 'Messages / month (1,200,000,001 - 2.4 billion)',
          aws: '$37.20 / 100,000',
        },
      ],
      subscriptionUrls: {
        aws: 'https://aws.amazon.com/marketplace/pp/prodview-s6bypsdwiyxfq',
      },
    },
    termsAndConditions: [
      { name: 'Cloud Subscription Agreement', url: 'https://www.intersystems.com/ICTerms' },
      { name: 'HCC Offering Specification', url: 'https://www.intersystems.com/IC-HCC' },
      { name: 'Secure Development Lifecycle', url: 'https://www.intersystems.com/SecureSDLC' },
      { name: 'Privacy Policy', url: 'https://www.intersystems.com/privacy-policy/' },
    ],
  },
  {
    name: 'InterSystems IRIS Cloud Managed Service',
    description: `Leverage the full capabilities of InterSystems IRIS® running in the Amazon AWS, while InterSystems handles the setup, ongoing operation, monitoring, reporting, high availability, disaster recovery, data security, and more, as well as updates for InterSystems software, the OS and other required operational software for the cloud.`,
    type: 'ics',
    productOverview: `<p>
    Leverage the full capabilities of InterSystems IRIS® running in the Amazon AWS, while InterSystems handles the setup, ongoing operation, monitoring, reporting, high availability, disaster recovery, data security, and more, as well as updates for InterSystems software, the OS and other required operational software for the cloud.
    `,
    productCode: SUBSCRIPTIONS_CODES.ics,
    photoPath: 'assets/images/logos/ics.png',
    pricing: {
      description: `This software is priced along a consumption dimension.
        Your bill will be determined by the number of units you use. Additional taxes or fees may apply.`,
      measure: 'Units',
      units: [],
    },
    termsAndConditions: [
      { name: 'Cloud Subscription Agreement', url: 'https://www.intersystems.com/ICTerms' },
      { name: 'Secure Development Lifecycle', url: 'https://www.intersystems.com/SecureSDLC' },
      { name: 'Privacy Policy', url: 'https://www.intersystems.com/privacy-policy/' },
    ],
  },
  {
    name: 'InterSystems Data Fabric Studio with supply chain module',
    description: `InterSystems Data Fabric Studio with supply chain module is a fully managed no-code/low-code cloud service which provides a unified source of data and actionable information for supply chain for business users and supply chain applications.`,
    type: 'scs',
    productOverview: `<p>
    InterSystems Data Fabric Studio with supply chain module is a fully managed no-code/low-code cloud service which provides a unified source of data and actionable information for supply chain for business users and supply chain applications.
    `,
    productCode: SUBSCRIPTIONS_CODES.scs,
    photoPath: 'assets/images/logos/scs.png',
    pricing: {
      description: `This software is priced along a consumption dimension.
        Your bill will be determined by the number of units you use. Additional taxes or fees may apply.`,
      measure: 'Units',
      units: [],
    },
    termsAndConditions: [
      { name: 'Cloud Subscription Agreement', url: 'https://www.intersystems.com/ICTerms' },
      { name: 'Secure Development Lifecycle', url: 'https://www.intersystems.com/SecureSDLC' },
      { name: 'Privacy Policy', url: 'https://www.intersystems.com/privacy-policy/' },
    ],
  },
  {
    name: 'FHIR Transformation Services',
    description: `Automate the transformation of health care data to FHIR
        R4 resources and deposit the resources into a cloud based data repository.`,
    type: 'hmts',
    productCode: SUBSCRIPTIONS_CODES.hmts,
    photoPath: 'assets/images/logos/hmts.png',
    awsUrl: 'https://aws.amazon.com/marketplace/pp/prodview-q7ryewpz75cq2?ref_=unifiedsearch',
    productOverview: `
      FHIR Transformation Services, a serverless and fully-automated cloud-based offering converts data formats starting with HL7v2 to FHIR® to populate Amazon HealthLake where you can store, query, and analyze your health data at scale.
      <br><br>
      Seamlessly deliver FHIR resources to Amazon HealthLake leveraging InterSystems pre-built transformation logic. A single, centralized interface makes it easy to configure a transformation pipeline, monitor and track message statistics and errors, view segments and fields converted for each FHIR resource element and more.
      <br><br>
      HealthShare Message Transformation Services converts a wide variety of HL7v2 message types out-of-the-box. Quickly get started and only pay for what you use with message-based pricing and no long term contracts. The first 1 million HL7 messages processed are free, with $100 per each subsequent 1 million messages after that. Please contact InterSystems for more information on custom transformations or special requirements.`,
    soldBy: {
      companyName: 'InterSystems Corporation',
      url: 'https://aws.amazon.com/marketplace/seller-profile?id=6e5272fb-ecd1-4111-8691-e5e24229826f',
    },
    productVideoId: 'R4Y3Py1nIjw',
    fulfillmentMethod: 'Software as a Service (SaaS)',
    termsAndConditions: [
      { name: 'Cloud Subscription Agreement', url: 'https://www.intersystems.com/ICTerms' },
      { name: 'Secure Development Lifecycle', url: 'https://www.intersystems.com/SecureSDLC' },
      { name: 'Privacy Policy', url: 'https://www.intersystems.com/privacy-policy/' },
    ],
    pricing: {
      description: `This software is priced along a consumption dimension.
          Your bill will be determined by the number of requests you make. Additional taxes or fees may apply.`,
      measure: 'Requests',
      units: [
        {
          name: 'HL7 Messages 0 - 1,000,000',
          aws: '$0 / request',
        },
        {
          name: 'HL7 Messages 1,000,001+ (1 request unit = 10 messages processed)',
          aws: '$0.001 / request',
        },
      ],
      subscriptionUrls: {
        aws: 'https://aws.amazon.com/marketplace/pp/prodview-q7ryewpz75cq2?ref_=unifiedsearch',
      },
    },
  },
  //uncomment when ready to enable IRIS Cloud SQL
  {
    name: 'InterSystems IRIS Cloud SQL',
    description: `Give your applications direct access to the advanced 
      relational database capabilities of InterSystems 
      IRIS® Data Platform without the burden of provisioning, 
      configuring, and maintaining cloud infrastructure. 
      The IRIS Cloud IntegratedML option lets you define and 
      execute predictive models by applying automated 
      functions directly from SQL, without requiring 
      extensive machine learning expertise.`,
    type: 'sqlaas',
    productCode: SUBSCRIPTIONS_CODES.sqlaas,
    photoPath: 'assets/images/subscriptions/cog.svg',
    //photoPath: 'assets/images/logos/sqlaas.png',
    awsUrl: 'https://aws.amazon.com/marketplace/pp/prodview-5pkffndgh52bi?ref_=unifiedsearch',
    productOverview: `
      InterSystems IRIS Cloud SQL makes it easy for application developers to leverage InterSystems advanced relational database capabilities as a fully managed, secure, scalable, high performance, highly available cloud-native database-as-a-service (DBaaS).
      <br><br>
      InterSystems IRIS Cloud SQL delivers the following benefits for SQL developers:
      <ul>
      <li>Extremely high performance, especially for ingesting and processing incoming data and performing SQL queries on the data with low latency at scale</li>
      <li>Fast and easy to provision and use</li>
      <li>Ability to easily connect client applications via JDBC, ODBC, DB-API, and ADO.NET drivers</li>
      <li>Automated security, data encryption, and backups</li>
      </ul>
      <br><br>
      <b>InterSystems IRIS Cloud IntegratedML</b>
      <br><br>
      Available as an additional cloud managed service for InterSystems IRIS Cloud SQL customers, InterSystems IRIS Cloud IntegratedML extends the capabilities of IRIS Cloud SQL to enable SQL developers to quickly build, train, and execute machine learning models with just a few SQL-like commands, without moving or copying data to a different environment.
      <br><br>
      InterSystems IRIS Cloud IntegratedML automates much of the tedious work involved in feature engineering and model building and tuning. It also empowers skilled data scientists to become more productive and focus on higher-value tasks. Models created and trained with InterSystems IRIS Cloud IntegratedML are embedded directly within InterSystems IRIS Cloud SQL and execute in response to real-time events and transactions.  
      `,
    soldBy: {
      companyName: 'InterSystems Corporation',
      url: 'https://aws.amazon.com/marketplace/seller-profile?id=6e5272fb-ecd1-4111-8691-e5e24229826f',
    },
    //productVideoId: 'y81Es5GCFsc',
    fulfillmentMethod: 'Software as a Service (SaaS)',
    termsAndConditions: [
      { name: 'Cloud Subscription Agreement', url: 'https://www.intersystems.com/ICTerms' },
      { name: 'Secure Development Lifecycle', url: 'https://www.intersystems.com/SecureSDLC' },
      { name: 'Privacy Policy', url: 'https://www.intersystems.com/privacy-policy/' },
    ],
    pricing: {
      description: `This software is priced along a consumption dimension.
        Your bill will be determined by the number of units you use. Additional taxes or fees may apply. Note: The first $300 of usage will be credited to you.`,
      measure: 'Units',
      units: [
        {
          name: 'vCPU deployed per hour',
          aws: '$0.91 / unit',
          //azure: 'Coming soon',
        },
        {
          name: 'Allocated storage per hour in 10 GB chunks',
          aws: '$0.005 / unit',
          //azure: 'Coming soon',
        },
        {
          name: 'Additional charge for IntegratedML feature per vCPU per hour',
          aws: '$0.228 / unit',
          //azure: 'Coming soon',
        },
      ],
      subscriptionUrls: {
        aws: 'https://aws.amazon.com/marketplace/pp/prodview-5pkffndgh52bi?ref_=unifiedsearch',
      },
    },
    allowISCSubscription: false,
    customSubscriptionMessage:
      'New users automatically receive a $300 credit, after which use of the service is billed to your cloud provider account.',
  },
  /*
  {
    name: 'SDS',
    description: `Smart Data Services`,
    type: 'sds',
    productCode: 'sds',
    photoPath: 'assets/images/subscriptions/cog.svg',
    //photoPath: 'assets/images/logos/sqlaas.png',
    //awsUrl: 'https://aws.amazon.com/marketplace/pp/prodview-ftb7xzb6t7c3w?ref_=unifiedsearch',
    productOverview: `
      Smart Data Services
      `,
    soldBy: {
      companyName: 'InterSystems Corporation',
      url: 'https://aws.amazon.com/marketplace/seller-profile?id=6e5272fb-ecd1-4111-8691-e5e24229826f',
    },
    //productVideoId: 'y81Es5GCFsc',
    fulfillmentMethod: 'Software as a Service (SaaS)',
    termsAndConditions: [
      { name: 'Cloud Subscription Agreement', url: 'https://www.intersystems.com/ICTerms' },
      { name: 'Secure Development Lifecycle', url: 'https://www.intersystems.com/SecureSDLC' },
      { name: 'Privacy Policy', url: 'https://www.intersystems.com/privacy-policy/' },
    ],
    pricing: {
      description: `TBD
        Your bill will be determined by the number of units you use. Additional taxes or fees may apply.`,
      measure: 'Units',
      units: [],
      subscriptionUrls: {
        //  aws: 'https://aws.amazon.com/marketplace/pp/prodview-ftb7xzb6t7c3w?ref_=unifiedsearch',
      },
    },
  },
  {
    name: 'DocDB',
    description: `InterSystems IRIS Cloud Document Service`,
    type: 'doc',
    productCode: 'doc',
    photoPath: 'assets/images/subscriptions/cog.svg',
    //photoPath: 'assets/images/logos/sqlaas.png',
    //awsUrl: 'https://aws.amazon.com/marketplace/pp/prodview-ftb7xzb6t7c3w?ref_=unifiedsearch',
    productOverview: `
      Smart Data Services
      `,
    soldBy: {
      companyName: 'InterSystems Corporation',
      url: 'https://aws.amazon.com/marketplace/seller-profile?id=6e5272fb-ecd1-4111-8691-e5e24229826f',
    },
    //productVideoId: 'y81Es5GCFsc',
    fulfillmentMethod: 'Software as a Service (SaaS)',
    termsAndConditions: [
      { name: 'Cloud Subscription Agreement', url: 'https://www.intersystems.com/ICTerms' },
      { name: 'Secure Development Lifecycle', url: 'https://www.intersystems.com/SecureSDLC' },
      { name: 'Privacy Policy', url: 'https://www.intersystems.com/privacy-policy/' },
    ],
    pricing: {
      description: `TBD
        Your bill will be determined by the number of units you use. Additional taxes or fees may apply.`,
      measure: 'Units',
      units: [],
      subscriptionUrls: {
        //  aws: 'https://aws.amazon.com/marketplace/pp/prodview-ftb7xzb6t7c3w?ref_=unifiedsearch',
      },
    },
  },*/
];
